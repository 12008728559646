<template>
    <div class="contact_info_box">
        <div style="padding-bottom:16px;height: 390px;">
            <p class="info">
                <span>销售订单编号</span>
                {{ order.orderNo ? order.orderNo : '- -' }}
            </p>
            <p class="info">
                <span>客户名称</span>
                {{ order.companyName ? order.companyName : '- -' }}
            </p>
            <p class="info">
                <span>成员</span>
                {{ order.adminName ? order.adminName : '- -' }}
            </p>
            <p class="info">
                <span>当前交付状态</span>
                {{
                    order.status == 1
                        ? '未交付'
                        : order.status == 2
                        ? '部分交付'
                        : order.status == 3
                        ? '已交付'
                        : '- -'
                }}
            </p>
            <p class="info">
                <span> <i style="color:red">*</i> 新状态</span>
                <el-select
                    v-model="status"
                    size="small"
                    style="width: 225px"
                    clearable
                    placeholder="请选择"
                >
                    <el-option label="未交付" :value="1"></el-option>
                    <el-option label="部分交付" :value="2"></el-option>
                    <el-option label="已交付" :value="3"></el-option>
                </el-select>
            </p>


            <p class="info">
                <span>预计下单时间</span>
                <el-date-picker
                                    v-model="estimateOrderTime"
                                    style="width: 210px"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="date"
                                    :clearable="false"
                                    placeholder="预计下单时间"
                                    :picker-options="expireTimeOption"
                                >
                                </el-date-picker>
            </p>

            <p class="info">
                <span>备注</span>
                <el-input
                    v-model="remark"
                    style="width: 210px"
                    placeholder="备注"
                ></el-input>
            </p>

            
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" @click="onSubmit" round>保 存</el-button>
        </div>
    </div>
</template>

<script>
import { orderUpdate } from '@/api/order/order.js';
export default {
    data() {
        return {
            order: {},
            status: '',
            estimateOrderTime:'',
            remark:''
        };
    },
    methods: {
        getData(row) {
            this.order = row;
            // console.log(row);
        },
        onClose() {
            this.$emit('close');
            this.status = '';
        },
        onSubmit() {
            if (!this.status) {
                return this.$message.error('请选择新状态');
            }
            let data = {
                param: {
                    id: this.order.id,
                    status: this.status,
                    estimateOrderTime: this.estimateOrderTime,
                    remark:this.remark
                },
            };
            orderUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('close');
                    this.status = '';
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contact_info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    .title {
        line-height: 1.2;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: #333;
        padding-left: 10px;
        > i {
            color: #2370eb;
            font-size: 18px;
        }
        button {
            background: rgb(255, 255, 255);
            color: rgb(35, 112, 235);
            border-color: rgb(35, 112, 235);
            padding: 5px 10px;
            float: right;
            margin-right: 20px;
        }
    }
    .el-form {
        margin-left: 25px;
        /deep/ .el-form-item__label {
            font-weight: 600 !important;
        }
    }
    .info {
        font-size: 14px;
        color: #333;
        padding-left: 107px;
        box-sizing: border-box;
        margin-top: 15px;
        span {
            display: inline-block;
            width: 90px;
        }
    }
}
.footer_btn {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    background-color: #fff;
    .el-button--primary {
        background-color: #2370eb;
        border-color: #2370eb;
    }
    button:last-child {
        margin-right: 30px;
    }
}
</style>
